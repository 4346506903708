import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Layout from '../components/Layout';
import loader from '../../static/img/loader.svg';

const Container = styled.div``;

const Image = styled.div`
    background-image: url(${props => props.image});
    background-size: cover;
    background-position: center center;
    filter: grayscale(70%);
    box-sizing: border-box;
    padding: 6em 0;
    text-align: center;
    display: flex;
    justify-content: center;
`;

const Header = styled.div`
    font-weight: 300;
    font-size: 2em;
    color: white;
    margin-bottom: 2em;
    text-transform: uppercase;
`;

const TextWrapper = styled.div`
    width: 30em;
`;
const Paragraph = styled.div`
    font-size: 1.2em;
    color: white;
`;

const FormContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media only screen and (min-width: 769px) {
        padding: 3em 1em;
    }
`;

const Address = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 1em;

    @media only screen and (min-width: 769px) {
        width: 33.3%;
        align-items: space-between;
    }
`;

const Wrapper = styled.div`
    text-align: center;
    margin-bottom: 2em;
    width: 100%;

    &:last-of-type {
        margin-bottom: 0;
    }

    @media only screen and (min-width: 769px) {
        margin-bottom: 0;
    }
`;

const SmallHeader = styled.h2`
    font-size: 1.5em;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 0.5em;
`;

const Info = styled.p`
    margin-bottom: 0.5em;
`;

const inputStyles = {
    boxSizing: 'border-box',
    padding: '1em',
    height: '2em',
    width: '100%',
    fontSize: '1em',
    border: '1px solid #ccc',
    fontWeight: 100,
    position: 'relative',
};

const Loader = styled.img`
    height: 2em;
`;

const disabledStyles = {
    backgroundColor: '#ccc',
};

const sendStyles = {
    backgroundColor: '#4caf50',
};

const SubmitButton = styled.button`
    background-color: #363534;
    color: white;
    height: 3em;
    width: 100%;
    text-align: center;
    bottom: 1em;
    left: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 1em;
    font-weight: 100;
    border: none;

    @media only screen and (min-width: 769px) {
        margin-left: auto;
        max-width: calc(50% - 2em);
    }

    ${props =>
        props.disabled
            ? css`
                  ${disabledStyles}
              `
            : ''}
    ${props =>
        props.isSend
            ? css`
                  ${sendStyles}
              `
            : ''}
`;

const FormWrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 1em;
    background-color: #fff;

    @media only screen and (min-width: 769px) {
        border: 1px solid #ccc;
        width: 66.6%;
    }
`;

const FieldWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
    position: relative;
`;

const Flex = styled.div`
    @media only screen and (min-width: 769px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
    }
`;

export const ContactPageTemplate = ({ images }) => {
    const [isSend, setIsSend] = useState(false);
    const [submitText, setSubmitText] = useState('Wyślij');
    return (
        <Container>
            <Image image={images[0]}>
                <TextWrapper>
                    <Header>Pozostań w kontakcie</Header>
                    <Paragraph>
                        Pytania? Obawy? Jesteśmy gotowi pomóc! Skorzystaj z
                        naszego wsparcia dzwoniąć lub wysyłając nam wiadomość.
                    </Paragraph>
                </TextWrapper>
            </Image>
            <FormContainer>
                <FormWrapper>
                    <Formik
                        initialValues={{ email: '', text: '' }}
                        onSubmit={(values, { setSubmitting }) => {
                            fetch(
                                'https://mail-api-277515.oa.r.appspot.com/contact',
                                {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        replyTo: values.email,
                                        message: values.text,
                                    }),
                                }
                            )
                                .then(() => {
                                    setSubmitText('Wysłano!');
                                    setIsSend(true);
                                })
                                .catch(() => {
                                    setSubmitText('Spróbuj ponownie');
                                    setSubmitting(false);
                                });
                        }}
                        validate={values => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = 'E-mail jest wymagany';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = 'Niepoprawny adres e-mail';
                            }
                            if (!values.text) {
                                errors.text = 'Wiadomość jest wymagana';
                            } else if (!/^.{3,}$/.test(values.text)) {
                                errors.text = 'Wymagane conajmniej 3 znaki';
                                return errors;
                            }
                            return errors;
                        }}
                    >
                        {({ isSubmitting }) => (
                            <Form
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <FieldWrapper>
                                    <Field
                                        name="email"
                                        placeholder="Twój email"
                                        style={{
                                            ...inputStyles,
                                        }}
                                        type="email"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="email"
                                        style={{
                                            position: 'absolute',
                                            bottom: '-1.5em',
                                            left: '0',
                                            color: 'red',
                                        }}
                                    />
                                </FieldWrapper>
                                <FieldWrapper>
                                    <Field
                                        as="textarea"
                                        name="text"
                                        placeholder="Twoja wiadomość"
                                        style={{
                                            ...inputStyles,
                                            resize: 'none',
                                            height: '8em',
                                        }}
                                        type="text"
                                    />
                                    <ErrorMessage
                                        component="div"
                                        name="text"
                                        style={{
                                            position: 'absolute',
                                            bottom: '-1.5em',
                                            left: '0',
                                            color: 'red',
                                        }}
                                    />
                                </FieldWrapper>
                                <SubmitButton
                                    disabled={isSubmitting}
                                    isSend={isSend}
                                    type="submit"
                                >
                                    {(!isSubmitting || isSend) && submitText}
                                    {isSubmitting && !isSend && (
                                        <Loader alt="loader" src={loader} />
                                    )}
                                </SubmitButton>
                            </Form>
                        )}
                    </Formik>
                </FormWrapper>
                <Address>
                    <Flex>
                        <Wrapper>
                            <SmallHeader>Email:</SmallHeader>
                            <Info>femarkuchnie@vp.pl</Info>
                        </Wrapper>
                        <Wrapper>
                            <SmallHeader>Telefon:</SmallHeader>
                            <Info>661 791 246</Info>
                            <Info>607 796 722</Info>
                        </Wrapper>
                        <Wrapper>
                            <SmallHeader>Adres:</SmallHeader>
                            <Info>Mąkoszyce 61</Info>
                            <Info>63-507 Kobyla Góra</Info>
                        </Wrapper>
                    </Flex>
                </Address>
            </FormContainer>
        </Container>
    );
};

ContactPageTemplate.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
};

const ContactPage = ({ data }) => {
    const {
        markdownRemark: {
            frontmatter: { image },
        },
    } = data;
    const img = image && image.childImageSharp.fluid.src;

    return (
        <Layout>
            <ContactPageTemplate images={[img]} />
        </Layout>
    );
};

ContactPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default ContactPage;

export const contactPageQuery = graphql`
    query ContactPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                image {
                    childImageSharp {
                        fluid(maxWidth: 2048, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
